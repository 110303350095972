import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';

import { Box, Container, Tab, TabPanels, Tabs, Typography } from '@core/component';
import { Translate } from '@core/constant';
import { useContextMediaQuery } from '@core/context';
import { EActionAttribute, EColor, ETypographyVariant, TabsChangeEvent } from '@core/type';
import { ESanityField, TabProduct } from '@core/type/sanity';

import { TabPanelProductDetails } from './TabPanelProductDetails';
import { TabWidgetDesktopBody } from './TabWidgetDesktop';
import { TabWidgetPhone } from './TabWidgetPhone';

const getTitle = (tab: TabProduct, t: (key: string) => string) => {
  switch (tab._type) {
    case ESanityField.PORTABLE_TEXT as string:
      return tab.title;
    case ESanityField.PRODUCT_SPECIFICITIES as string:
      return t('tabProduct.titleSpecifications');
    case 'overview':
      return t('tabProduct.titleDescription');
    case 'history':
      return t('tabProduct.titleHistory');
    default:
      return '';
  }
};

export const ProductTabWidget = ({ tabs }: { tabs: TabProduct[] }) => {
  const { isPhone, isMobile } = useContextMediaQuery();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const { t } = useTranslation(Translate.common.WIDGET);

  const handleTabs = ({ target: { value } }: TabsChangeEvent<number>) => {
    setCurrentTab(value);
  };

  return (
    <Container
      as={EActionAttribute.SECTION}
      gutter={0}
      maxWidth={'946px'}
      marginBottom={isMobile ? '64px' : '104px'}
    >
      {isPhone ? (
        <Box display={'flex'} flexDirection={'column'} rowGap={'16px'}>
          {tabs.map((tab) => (
            <TabWidgetPhone tab={tab} key={tab._key} />
          ))}
        </Box>
      ) : (
        <>
          <Tabs<number> value={currentTab} onChange={handleTabs}>
            {tabs.map((tab, index) => (
              <Tab
                value={index}
                hasBoxShadow={false}
                key={tab._key}
                lineActiveColor={EColor.ACCENT}
                backgroundActiveColor={{ semanticType: EColor.SECONDARY, variant: EColor.LIGHT }}
                childrenActive={
                  <Typography
                    as={EActionAttribute.H2}
                    variant={ETypographyVariant.H6}
                    color={EColor.ACCENT}
                  >
                    {getTitle(tab, t)}
                  </Typography>
                }
              >
                <Typography
                  as={EActionAttribute.H2}
                  variant={ETypographyVariant.H6}
                  color={EColor.PRIMARY}
                >
                  {getTitle(tab, t)}
                </Typography>
              </Tab>
            ))}
          </Tabs>
          <TabPanels<number> value={currentTab}>
            {tabs.map((tab, index) => (
              <TabPanelProductDetails value={index} key={tab._key}>
                <TabWidgetDesktopBody tab={tab} />
              </TabPanelProductDetails>
            ))}
          </TabPanels>
        </>
      )}
    </Container>
  );
};
