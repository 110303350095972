import useTranslation from 'next-translate/useTranslation';

import { Translate } from '@core/constant';
import { ELanguageTags, ELiveChartsPeriod, EMetal, EUnit } from '@core/type';
import { getTranslationKeyFromMetal } from '@core/util';

import {
  getFirstPrefix,
  getOptionsForMetal,
  getOptionsForWeightUnit,
  getSecondPrefix,
  getTranslationKeyFromPeriod,
  getTranslationKeyFromWeightUnit,
} from './utils-translation';

export const useCharts = ({
  language,
  metal,
  period,
  weightUnit,
}: {
  language: ELanguageTags;
  metal: EMetal;
  period: ELiveChartsPeriod;
  weightUnit: EUnit;
}) => {
  const { t: tProduct } = useTranslation(Translate.common.PRODUCT);
  const { t } = useTranslation(Translate.common.WIDGET);

  const optionsForWeightUnit = getOptionsForWeightUnit(t);

  const optionsForMetal = getOptionsForMetal(tProduct);

  const categoryTitle = tProduct(getTranslationKeyFromMetal(metal));
  const categoryTitleDowncase =
    language === ELanguageTags.DE_DE ? categoryTitle : categoryTitle.toLowerCase();
  const periodTranslation = t(getTranslationKeyFromPeriod(period));
  const firstPrefix = getFirstPrefix(metal, language);
  const secondPrefix = getSecondPrefix(metal, language);

  const weightTranslation = t(getTranslationKeyFromWeightUnit(weightUnit));

  return {
    categoryTitleDowncase,
    categoryTitle,
    periodTranslation,
    firstPrefix,
    secondPrefix,
    weightTranslation,
    optionsForWeightUnit,
    optionsForMetal,
  };
};
