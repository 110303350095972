import useTranslation from 'next-translate/useTranslation';
import { ReactNode, useContext } from 'react';

import { Accordion, Box, Typography } from '@core/component';
import { Translate } from '@core/constant';
import { productContext } from '@core/context';
import { useLanguage, useQueryApi } from '@core/hook';
import {
  EAccordionClosingType,
  EActionAttribute,
  EColor,
  EFontWeight,
  EIcon,
  ESize,
  ETypographyVariant,
} from '@core/type';
import { EQueryKey, ProductPageEntity } from '@core/type/api';
import { ESanityField, SanityPageResponse, TabProduct } from '@core/type/sanity';
import { formatUnitShort } from '@core/util';

import { PortableText } from '../portable-text';
import { LineSpecification } from './LineSpecification';

export const TabWidgetPhone = ({ tab }: { tab: TabProduct }) => {
  const { language } = useLanguage();

  const { t } = useTranslation(Translate.common.WIDGET);
  const product = useContext(productContext);
  const { data } = useQueryApi<ProductPageEntity, SanityPageResponse>(
    EQueryKey.PRODUCT_SHOW,
    {
      id: product.id,
    } as SanityPageResponse & { id: number },
    { enabled: !!product?.id },
  );

  const specificities = data?.specificities;

  const diameterProps =
    specificities?.diameter?.number &&
    formatUnitShort(language, specificities?.diameter.unit, specificities?.diameter.number);

  const lengthProps =
    specificities?.length?.number &&
    formatUnitShort(language, specificities?.length.unit, specificities?.length.number);

  const widthProps =
    specificities?.width?.number &&
    formatUnitShort(language, specificities?.width.unit, specificities?.width.number);

  const weightProps = specificities?.weight?.filter(({ number }) => number) || [];

  return tab._type === (ESanityField.PRODUCT_SPECIFICITIES as string) ? (
    <AccordionProductDetails
      title={tab.title ?? t('tabProduct.titleSpecifications')}
      key={tab._key}
      content={
        <>
          <LineSpecification
            title={t('tabProduct.specifications.productId')}
            value={specificities?.sku}
          />
          <LineSpecification
            title={t('tabProduct.specifications.year')}
            value={specificities?.issueYear}
          />
          <LineSpecification
            title={t('tabProduct.specifications.mintMark')}
            value={specificities?.mintMark}
          />
          <LineSpecification
            title={t('tabProduct.specifications.thickness')}
            value={specificities?.thickness}
          />
          <LineSpecification
            title={t('tabProduct.specifications.purity')}
            value={specificities?.purity}
          />
          {weightProps.map(({ number, unit }) => (
            <LineSpecification
              key={unit}
              title={t(`tabProduct.specifications.${unit}`)}
              value={formatUnitShort(language, unit, number)}
            />
          ))}
          <LineSpecification
            title={t('tabProduct.specifications.diameter')}
            value={diameterProps}
          />
          <LineSpecification title={t('tabProduct.specifications.length')} value={lengthProps} />
          <LineSpecification
            title={t('tabProduct.specifications.width')}
            value={widthProps}
            hasLine={false}
          />
        </>
      }
    />
  ) : (
    <AccordionProductDetails
      key={tab._key}
      title={tab.title}
      content={<PortableText value={tab.content} />}
    />
  );
};

const AccordionProductDetails = ({ title, content }: { title: ReactNode; content: ReactNode }) => {
  return (
    <Accordion
      titleContent={
        <Typography
          as={EActionAttribute.H2}
          variant={ETypographyVariant.H5}
          fontWeight={EFontWeight.MEDIUM}
        >
          {title}
        </Typography>
      }
      titleActiveContent={
        <Typography
          variant={ETypographyVariant.H5}
          fontWeight={EFontWeight.MEDIUM}
          color={EColor.ACCENT}
        >
          {title}
        </Typography>
      }
      titlePadding={'20px'}
      borderRadius={'10px'}
      iconSize={ESize.SM}
      defaultIcon={EIcon.PLUS}
      activeIcon={EIcon.MINUS}
      iconActiveColor={EColor.ACCENT}
      backgroundColor={{ semanticType: EColor.SECONDARY, variant: EColor.LIGHT }}
      closingType={EAccordionClosingType.FULL_CLICK}
    >
      <Box padding={'0 20px 20px'}>{content}</Box>
    </Accordion>
  );
};
