import { PortableText } from '@portabletext/react';
import useTranslation from 'next-translate/useTranslation';
import { useContext } from 'react';
import { PortableTextBlock } from 'sanity';

import { Grid } from '@core/component';
import { Translate } from '@core/constant';
import { productContext } from '@core/context';
import { useLanguage, useQueryApi } from '@core/hook';
import { EQueryKey, ProductPageEntity } from '@core/type/api';
import { ESanityField, SanityPageResponse, TabProduct } from '@core/type/sanity';
import { formatUnitShort } from '@core/util';

import { LineSpecification } from './LineSpecification';

export const TabWidgetDesktopBody = ({ tab }: { tab: TabProduct }) => {
  const { language } = useLanguage();

  const { t } = useTranslation(Translate.common.WIDGET);
  const product = useContext(productContext);
  const { data } = useQueryApi<ProductPageEntity, SanityPageResponse>(
    EQueryKey.PRODUCT_SHOW,
    {
      id: product.id,
    } as SanityPageResponse & { id: number },
    { enabled: !!product?.id },
  );

  const specificities = data?.specificities;

  const diameterProps =
    specificities?.diameter?.number &&
    formatUnitShort(language, specificities?.diameter.unit, specificities?.diameter.number);

  const lengthProps =
    specificities?.length?.number &&
    formatUnitShort(language, specificities?.length.unit, specificities?.length.number);

  const widthProps =
    specificities?.width?.number &&
    formatUnitShort(language, specificities?.width.unit, specificities?.width.number);

  const weightProps = specificities?.weight?.filter(({ number }) => number) || [];

  return tab._type === (ESanityField.PRODUCT_SPECIFICITIES as string) ? (
    <Grid isContainer columnSpacing={114}>
      <Grid md={6}>
        <LineSpecification
          title={t('tabProduct.specifications.productId')}
          value={specificities?.sku}
        />
        <LineSpecification
          title={t('tabProduct.specifications.year')}
          value={specificities?.issueYear}
        />
        <LineSpecification
          title={t('tabProduct.specifications.mintMark')}
          value={specificities?.mintMark}
        />
        <LineSpecification
          title={t('tabProduct.specifications.thickness')}
          value={specificities?.thickness}
        />
        <LineSpecification
          title={t('tabProduct.specifications.purity')}
          value={specificities?.purity}
        />
      </Grid>
      <Grid md={6}>
        {weightProps.map(({ number, unit }) => (
          <LineSpecification
            key={unit}
            title={t(`tabProduct.specifications.${unit}`)}
            value={formatUnitShort(language, unit, number)}
          />
        ))}
        <LineSpecification title={t('tabProduct.specifications.diameter')} value={diameterProps} />
        <LineSpecification title={t('tabProduct.specifications.length')} value={lengthProps} />
        <LineSpecification title={t('tabProduct.specifications.width')} value={widthProps} />
      </Grid>
    </Grid>
  ) : (
    <PortableText value={(tab as { content: PortableTextBlock }).content} />
  );
};
